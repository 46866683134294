import { useStore } from "@/store"
import { computed } from "vue"
import { useSchoolsUser } from "@/composables/useSchoolsUser"


export function useQuizzesSubscription(subscription) {
  const store = useStore()

  const schoolsUser = computed(() => {
    return store.getters.schoolsUser(subscription?.schools_user_id)
  })

  const schoolsUserFacade = computed(() => {
    return useSchoolsUser(schoolsUser.value).facade()
  })

  const score = computed(() => {
    return store.getters.scoreByQuizAndUserId(subscription?.quiz_id, schoolsUser.value?.user_id)
  })

  const timeAccommodation = computed(() => {
    return store.getters.timeAccommodation(subscription?.time_accommodation_id)
  })

  function facade() {
    return {
      ...subscription,
      cheating_attempt_count: score.value?.cheating_attempt_count,
      teacher_cheat_confirmation: score.value?.teacher_cheat_confirmation,
      answered_questions_count_cache: score.value?.answered_questions_count_cache,
      status: score.value?.state || "not_started",
      schoolsUser: schoolsUser.value,
      timeAccommodation: timeAccommodation.value,
      score: score.value,
    }
  }

  return {
    facade,
    schoolsUser,
    schoolsUserFacade,
    score,
    timeAccommodation,
  }
}
